import React from 'react';

export default class UserDashboardScreen extends React.Component {

    render() {
        return (
            <div className="screen-with-app-bar screen-centered-content">
                <h2>My Dashboard!</h2>
            </div>
        );
    }

}
