export const actions = {
    SHOW_LOADING: 'SHOW_LOADING',
    HIDE_LOADING: 'HIDE_LOADING',
    SET_LOADING: 'SET_LOADING',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_AUTH_USER: 'SET_AUTH_USER',
};

export const showLoading = {
    type: actions.SHOW_LOADING,
};

export const hideLoading = {
    type: actions.HIDE_LOADING,
};

export const setLoading = (value) => {
    return {
        type: actions.SET_LOADING,
        value: value,
    };
};

export const setErrorMessage = (value) => {
    return {
        type: actions.SET_ERROR_MESSAGE,
        value: value,
    };
};

export const setAuthUser = (value) => {
    return {
        type: actions.SET_AUTH_USER,
        value: value,
    };
};
