// import Moment from "react-moment";

const API_SERVICE_ENDPOINT = window.LZ_API_ENDPOINT_URI;

export const callApi = async (method, params) => {

    // Init based on action type
    let initData = {};
    let url = API_SERVICE_ENDPOINT + method;

    // Make the app authentication params
//    const ts = Moment.getDatetime();
//    params.ts = ts;

    // Make call params
    if ('get' === method) {
        // Edit the url with query string
        url += '?' + Object.entries(params).map((p) => p[0] + '=' + encodeURIComponent(p[1])).join('&');

        // Make init data
        initData = {
            method: 'GET',
        };
    } else if ('' === method) {
        // Set data to send for api call
        const data = new FormData();
        if (typeof params === 'object') {
            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                data.append(keys[i], params[keys[i]]);
            }
        }

        // Make init data
        initData = {
            method: 'POST',
            body: data,
        };
    }

    // Call the api with await
    console.log('API CALL: ', url);
    const response = await fetch(url, initData);
    console.log('API RESP: ', response);

    // If response not ok then throw the proper error message
    // NOTE: Commented because our backend throws errors with 500 code so response.ok fails and it just prints
    //          Internal Server Error, while a detailed error is there (which is parsed and printed below).
    /*if (!response.ok) {
        throw Error(response.statusText);
    }*/

    // Parse the json out of the response
    const json = await response.json();
    console.log('API DATA: ', json);

    // Handle the data
    if (!json || typeof json.data === 'undefined' || typeof json.message === 'undefined') {
        throw Error(json.message || 'Invalid data returned from server.');
    }
    if (!json.success) {
        throw Error(json.message);
    }

    // Return actual data to calling function
    return json.data;

};
