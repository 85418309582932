import {actions} from '../actions';

const defaultState = {
    user: null,
};

const auth = (state = defaultState, action) => {

    switch (action.type) {

        case actions.SET_AUTH_USER:
            return Object.assign({}, state, {user: action.value});

        default:
            return state;

    }

};

export default auth;
