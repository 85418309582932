import React from 'react';

export default class UserProfileScreen extends React.Component {

    render() {
        return (
            <div className="screen-with-app-bar screen-centered-content">
                <h2>Update Profile!</h2>
            </div>
        );
    }

}
