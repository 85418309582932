// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';

import {routing} from './AppRouting';
import {isPWA} from "./services/Utils";
import * as serviceWorker from './serviceWorker';

import './scss/index.scss';

ReactDOM.render(routing, document.getElementById('root'));

if (isPWA()) {
    console.log('In PWA... using Service Worker!');
    serviceWorker.register();
} else {
    console.log('Not in PWA... ignoring Service Worker!');
    serviceWorker.unregister();
}
