import {actions} from '../actions';

const defaultState = {
    loading: false,
    errorMessage: '',
};

const commons = (state = defaultState, action) => {

    switch (action.type) {

        case actions.SHOW_LOADING:
            return Object.assign({}, state, {loading: true});

        case actions.HIDE_LOADING:
            return Object.assign({}, state, {loading: false});

        case actions.SET_LOADING:
            return Object.assign({}, state, {loading: action.value});

        case actions.SET_ERROR_MESSAGE:
            return Object.assign({}, state, {errorMessage: action.value});

        default:
            return state;

    }

};

export default commons;
