export const isAndroid = () => {
    return 0 === window.location.href.indexOf('file:///');
};

export const isIOS = () => {
    // TODO: Identify iOS
    return false;
};

export const isPWA = () => {
    return 0 === window.location.href.indexOf('https://scope.pk/');
};

export const getAssetUrl = (reactPath) => {
    let out = reactPath;
    let ident = 'Default';

    if (isAndroid()) {
        ident = 'Android';
        out = out.replace('./static', 'file:///android_asset/static');
    }

    else if (isIOS()) {
        ident = 'iOS';
        // TODO: Implement for iOS
    }

    else if (isPWA()) {
        ident = 'PWA';
        // Its pwa so leave it as is
    }

    // TODO: Other cases come here

    console.log('LOC', ident, window.location.href, out);

    return out;
};
