import {createMuiTheme} from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#be4eff',
            main: '#8500ff',
            dark: '#4700ca',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffc747',
            main: '#fa9600',
            dark: '#c16800',
            contrastText: '#fff',
        },
    },
});
