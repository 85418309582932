import React from 'react';
import Button from "@material-ui/core/Button";
import {actions} from "../../../redux/actions";
import {connect} from "react-redux";

class UserLoginScreen extends React.Component {

    render() {
        return (
            <div className="screen-with-app-bar screen-centered-content">
                <h2>Login Form!</h2>
                {this.props.authUser ?
                    <div>
                        <Button href="#!" variant="contained" color="secondary" style={{color: 'white'}}
                                onClick={() => this.props.setAuthUser(null)}>Fake Logout</Button>
                    </div> : <div className="screen-centered-content">
                        <Button href="#!" variant="contained" color="primary" style={{color: 'white'}}
                                onClick={() => this.props.setAuthUser({type: 'candidate'})}>Fake Candidate
                            Login</Button>
                        <br/>
                        <Button href="#!" variant="contained" color="primary" style={{color: 'white'}}
                                onClick={() => this.props.setAuthUser({type: 'employer'})}>Fake Employer Login</Button>
                    </div>}
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.commons.loading,
        errorMessage: state.commons.errorMessage,
        authUser: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch({type: actions.SET_LOADING, value: value}),
        setErrorMessage: (value) => dispatch({type: actions.SET_ERROR_MESSAGE, value: value}),
        setAuthUser: (value) => dispatch({type: actions.SET_AUTH_USER, value: value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserLoginScreen);
