import React from 'react';
import {Route, BrowserRouter as Router} from 'react-router-dom';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/core/styles';

import App from './App';
import appStore from './redux/reducers';
import {theme} from "./AppTheme";
import HomeScreen from "./components/screens/public/home";
import UserDashboardScreen from './components/screens/users/UserDashboard';
import UserProfileScreen from "./components/screens/users/UserProfile";
import UserPasswordScreen from "./components/screens/users/UserPassword";
import UserJobAlertsScreen from "./components/screens/users/UserJobAlerts";
import UserSavedJobsScreen from "./components/screens/users/UserSavedJobs";
import UserLoginScreen from "./components/screens/users/UserLogin";
import UserRegisterScreen from "./components/screens/users/UserRegister";
// import NotFoundScreen from "./components/screens/commons/NotFound";
// This above is used with Switch which is not good, see below NOTE

// NOTE: We are not using below so that App component is rendered on all screens and thus the tabs on bottom are displayed
//          <Route exact path="/" component={App}/>
//                  ^ note this
//       *** Also note that using Switch will destroy this behaviour ***

const store = createStore(appStore);

export const routing = (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router>
                <div>
                    <Route path="/" component={App}/>
                    <Route path="/home" component={HomeScreen}/>

                    <Route path="/user-login" component={UserLoginScreen}/>
                    <Route path="/user-register" component={UserRegisterScreen}/>
                    <Route path="/user-dashboard" component={UserDashboardScreen}/>
                    <Route path="/user-profile" component={UserProfileScreen}/>
                    <Route path="/user-password" component={UserPasswordScreen}/>
                    <Route path="/user-job-alerts" component={UserJobAlertsScreen}/>
                    <Route path="/user-saved-jobs" component={UserSavedJobsScreen}/>
                </div>
            </Router>
        </Provider>
    </ThemeProvider>
);
