import React from 'react';
import {AppBar} from '@material-ui/core';
import {Link} from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {connect} from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Moment from 'react-moment';

import MenuIcon from '@material-ui/icons/Menu';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreVert from '@material-ui/icons/MoreVert';
import Dashboard from '@material-ui/icons/Dashboard';
import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import FindInPage from '@material-ui/icons/FindInPage';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import LiveHelp from '@material-ui/icons/LiveHelp';
import Book from '@material-ui/icons/Book';
import Info from '@material-ui/icons/Info';
import ContactSupport from '@material-ui/icons/ContactSupport';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MenuBook from '@material-ui/icons/MenuBook';
import Assignment from '@material-ui/icons/Assignment';
import FileCopy from '@material-ui/icons/FileCopy';
import ListAlt from '@material-ui/icons/ListAlt';
import PostAdd from '@material-ui/icons/PostAdd';

import {actions} from './redux/actions';
import ImgLogo from './assets/images/logo-on-dark.png';
import {getAssetUrl} from './services/Utils';
import {callApi} from "./services/ApiService";

import './scss/App.scss';

/**
 * NOTE: This component is rendered on all screens because we are not using "exact" prop in the routing definition
 *          see the file AppRouting.js for more info on this. This is just a trick, not a programming method.
 */
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mnuProfileAnchorEl: null,
            mnuAuthAnchorEl: null,
            navDrawerOpen: false,
            cats: [],
        };
    }

    componentDidMount() {
        // Call the initial data loader
        // this.load();

        // If loaded route is root, then go to /home route
        if (
            '/' === this.props.location.pathname ||                             // Check for browser
            '/app/' === this.props.location.pathname ||                         // Check for pwa app
            '/android_asset/index.html' === this.props.location.pathname        // Check for Android
        // TODO: Check for iOS
        ) {
            this.props.setLoading(true);
            const context = this;
            setTimeout(function () {
                context.props.history.push('/home');
                context.props.setLoading(false);
            }, 500);
        }
    }

    async load() {
        // If already loading then skip
        if (this.props.loading) {
            return;
        }

        // Tell app that we are loading
        this.props.setLoading(true);

        // Call api with error handling
        try {
            // Make the api call and get processed data
            const data = await callApi('get', {
                action: 'init',
            });

            // Validated data
            if (!data || !data.cats) {
                this.props.setErrorMessage('Invalid data from server!');
            } else {
                this.setState({
                    cats: data.cats,
                });
            }

            // Hide loading
            this.props.setLoading(false);
        } catch (e) {
            console.log('ERROR', e);
            this.props.setErrorMessage(e.toString());
            this.props.setLoading(false);
        }
    }

    handleMenuClose() {
        this.setState({
            mnuProfileAnchorEl: null,
            mnuAuthAnchorEl: null,
            navDrawerOpen: false,
        });
    }

    render() {
        const handleMenuClose = this.handleMenuClose.bind(this);

        return (
            <div className="app-bar">

                <div className="flex-grow-1">
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton href="#!" edge="start" className="menu-button" color="inherit"
                                        onClick={() => this.setState({navDrawerOpen: true})}>
                                <MenuIcon/>
                            </IconButton>
                            <Link to="/home">
                                <img src={getAssetUrl(ImgLogo)} alt="logo" className="logo"/>
                            </Link>
                            <div className="flex-grow-1"/>
                            {!!this.props.authUser ? <div>
                                <IconButton href="#!">
                                    <Badge badgeContent={4} color="secondary">
                                        <MailIcon/>
                                    </Badge>
                                </IconButton>
                                <IconButton href="#!" color="inherit">
                                    <Badge badgeContent={17} color="secondary">
                                        <NotificationsIcon/>
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    href="#!"
                                    onClick={(event) => this.setState({mnuProfileAnchorEl: event.currentTarget})}
                                    color="inherit">
                                    <AccountCircle/>
                                </IconButton>
                            </div> : <div>
                                <IconButton
                                    edge="end"
                                    href="#!"
                                    onClick={(event) => this.setState({mnuAuthAnchorEl: event.currentTarget})}
                                    color="inherit">
                                    <MoreVert/>
                                </IconButton>
                            </div>}
                        </Toolbar>
                    </AppBar>

                    <div className="copyright-bar">
                        <div>&copy; <Moment format="YYYY"/> <a href="https://scope.pk" target="_blank" rel="noopener noreferrer">Scope.pk</a> All rights reserved!
                        </div>
                        <div>Powered by <a href="https://logic-zone.net" target="_blank" rel="noopener noreferrer">Logic Zone</a></div>
                    </div>

                    <Drawer open={this.state.navDrawerOpen} onClose={handleMenuClose} className="sidenav">
                        <Link to="/home" onClick={handleMenuClose}>
                            <img src={getAssetUrl(ImgLogo)} alt="logo"/>
                        </Link>
                        {!!this.props.authUser && 'candidate' === this.props.authUser.type && <div>
                            <h3>Candidates</h3>
                            <Divider component="hr"/>
                            <List component="nav">
                                <ListItem component={Link} to="/user-upload-resume" button key="nav-user-upload-resume"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><MenuBook/></ListItemIcon>
                                    <ListItemText>Upload My Resume</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/user-work-profile" button key="nav-user-work-profile"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><Assignment/></ListItemIcon>
                                    <ListItemText>My Work Profile</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/user-applications" button key="nav-user-applications"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><FileCopy/></ListItemIcon>
                                    <ListItemText>My Applications</ListItemText>
                                </ListItem>
                            </List>
                        </div>}
                        {!!this.props.authUser && 'employer' === this.props.authUser.type && <div>
                            <h3>Employers</h3>
                            <Divider component="hr"/>
                            <List component="nav">
                                <ListItem component={Link} to="/user-post-internship" button
                                          key="nav-user-post-internship"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><PostAdd/></ListItemIcon>
                                    <ListItemText>Post an Internship</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/user-my-internships" button
                                          key="nav-user-my-internships"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><ListAlt/></ListItemIcon>
                                    <ListItemText>My Posted Internships</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/user-post-job" button key="nav-user-post-job"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><PostAdd/></ListItemIcon>
                                    <ListItemText>Post a Job</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/user-my-jobs" button key="nav-user-my-jobs"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><ListAlt/></ListItemIcon>
                                    <ListItemText>My Posted Jobs</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/user-applications" button key="nav-user-applications"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><FileCopy/></ListItemIcon>
                                    <ListItemText>Browse Applications</ListItemText>
                                </ListItem>
                            </List>
                        </div>}
                        <div>
                            <h3>Navigation</h3>
                            <Divider component="hr"/>
                            <List component="nav">
                                <ListItem component={Link} to="/internships" button key="nav-internships"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><AssignmentInd/></ListItemIcon>
                                    <ListItemText>Internships</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/jobs" button key="nav-jobs"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><FindInPage/></ListItemIcon>
                                    <ListItemText>Search for Jobs</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/candidates" button key="nav-candidates"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><SupervisorAccount/></ListItemIcon>
                                    <ListItemText>Candidates</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/employers" button key="nav-employers"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><VerifiedUser/></ListItemIcon>
                                    <ListItemText>Employers</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/career" button key="nav-career"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><LiveHelp/></ListItemIcon>
                                    <ListItemText>Career Guidance</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/blogs" button key="nav-blogs"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><Book/></ListItemIcon>
                                    <ListItemText>Blogs</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/about" button key="nav-about"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><Info/></ListItemIcon>
                                    <ListItemText>About Us</ListItemText>
                                </ListItem>
                                <ListItem component={Link} to="/contact" button key="nav-contact"
                                          onClick={handleMenuClose}>
                                    <ListItemIcon><ContactSupport/></ListItemIcon>
                                    <ListItemText>Contact Us</ListItemText>
                                </ListItem>
                            </List>
                        </div>
                    </Drawer>
                    <Menu
                        anchorEl={this.state.mnuProfileAnchorEl}
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={!!this.state.mnuProfileAnchorEl}
                        onClose={handleMenuClose}>
                        <MenuItem component={Link} to="/user-dashboard" dense button={true} onClick={handleMenuClose}>
                            <Dashboard className="menu-icon"/>
                            My Dashboard
                        </MenuItem>
                        <MenuItem component={Link} to="/user-password" dense button={true} onClick={handleMenuClose}>
                            <Lock className="menu-icon"/>
                            Change Password
                        </MenuItem>
                        <MenuItem component={Link} to="/user-profile" dense button={true} onClick={handleMenuClose}>
                            <Person className="menu-icon"/>
                            Update Profile
                        </MenuItem>
                        <MenuItem component={Link} to="/logout" dense button={true} onClick={handleMenuClose}>
                            <ExitToApp className="menu-icon"/>
                            Logout
                        </MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={this.state.mnuAuthAnchorEl}
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={!!this.state.mnuAuthAnchorEl}
                        onClose={handleMenuClose}>
                        <MenuItem component={Link} to="/user-login" dense button={true} onClick={handleMenuClose}>
                            <Lock className="menu-icon"/>
                            Login
                        </MenuItem>
                        <MenuItem component={Link} to="/user-register" dense button={true} onClick={handleMenuClose}>
                            <Person className="menu-icon"/>
                            Register
                        </MenuItem>
                    </Menu>
                </div>

                <Paper square>
                </Paper>

                {this.props.loading && <LinearProgress style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                }}/>}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.props.errorMessage !== ''}
                    autoHideDuration={4000}
                    onClose={() => this.props.setErrorMessage('')}
                >
                    <SnackbarContent
                        className="app-snack-bar"
                        message={
                            <div id="client-snackbar">
                                <ErrorIcon className="icon"/>
                                <div className="text">{this.props.errorMessage}</div>
                            </div>
                        }
                        action={[
                            <IconButton href="#!" key="close" color="inherit"
                                        onClick={() => this.props.setErrorMessage('')}>
                                <CloseIcon style={{fontSize: 20}}/>
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.commons.loading,
        errorMessage: state.commons.errorMessage,
        authUser: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch({type: actions.SET_LOADING, value: value}),
        setErrorMessage: (value) => dispatch({type: actions.SET_ERROR_MESSAGE, value: value}),
        setAuthUser: (value) => dispatch({type: actions.SET_AUTH_USER, value: value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
