import React from 'react';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";

import {actions} from "../../../../redux/actions";
import {connect} from "react-redux";

import './index.scss';
import {callApi} from "../../../../services/ApiService";
import {Icon} from "@material-ui/core";

class HomeScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cats: [],
        };
    }

    componentDidMount() {
        // Call the initial data loader
        this.load();
    }

    async load() {
        // If already loading then skip
        if (this.props.loading) {
            return;
        }

        // Tell app that we are loading
        this.props.setLoading(true);

        // Call api with error handling
        try {
            // Make the api call and get processed data
            const data = await callApi('get', {
                action: 'home',
            });

            // Validated data
            if (!data || !data.cats) {
                this.props.setErrorMessage('Invalid data from server!');
            } else {
                this.setState({
                    cats: data.cats,
                });
            }

            // Hide loading
            this.props.setLoading(false);
        } catch (e) {
            console.log('ERROR', e);
            this.props.setErrorMessage(e.toString());
            this.props.setLoading(false);
        }
    }

    render() {
        return (
            <div className="screen-with-app-bar screen-centered-content home-screen">
                <h1><span className="secondary">Shaping</span> the Future!</h1>
                <small className="center-text p1">Create free account to find thousands of Jobs, Employment & Career
                    Opportunities around you!</small>
                <div className="buttons-row">
                    <Button variant="contained" color="primary">Jobs</Button>
                    <Button variant="contained" color="secondary">Resumes</Button>
                </div>
                <hr className="sep"/>
                <h3>Browse Categories</h3>
                <div className="cats-wrapper">
                    {this.state.cats.map((cat) =>
                        <Card className="cat-item" key={'hcat-' + cat.id}>
                            {cat.thumb_url !== '' && <img src={cat.thumb_url} alt="icon"/>}
                            <h4>{cat.name}</h4>
                        </Card>)}
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.commons.loading,
        errorMessage: state.commons.errorMessage,
        authUser: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch({type: actions.SET_LOADING, value: value}),
        setErrorMessage: (value) => dispatch({type: actions.SET_ERROR_MESSAGE, value: value}),
        setAuthUser: (value) => dispatch({type: actions.SET_AUTH_USER, value: value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeScreen);
